<template>
    <div class="testimonial testimonial_style--1 h-100">
        <div class="content d-flex flex-row flex-wrap h-100">
            <p class="align-self-start">{{testimonial.text}}</p>
            <div class="d-flex flex-row flex-wrap w-100 align-self-end">
              <div class="testimonial-info w--100">
                <div class="post-thumbnail">
                  <img :src="testimonial.thumb" :alt="testimonial.alt">
                </div>
                <div class="clint-info">
                  <h6>{{ testimonial.name }}</h6>
                  <span>{{ testimonial.position }}</span>
                </div>
              </div>
              <div class="testimonial-quote w-100">
                <span class="fa fa-quote-right"></span>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['testimonial']
    };
</script>

<style scoped lang="scss">
.post-thumbnail {
  > img {
    border-radius: 50%;
  }
  background-color: #ddd;
  border-radius: 50%;
}
</style>
